import * as React from 'react';

function Shopify() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 170 50"
      enableBackground="new 0 0 170 50"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <path d="M6.982,47C4.51,47,2.5,45.025,2.5,42.598V8.402C2.5,5.975,4.51,4,6.982,4h156.037c2.471,0,4.482,1.975,4.482,4.402v34.196    c0,2.427-2.01,4.402-4.482,4.402H6.982z" />
          <path
            fill="#C3CCD4"
            d="M163.019,47.375H6.981c-2.678,0-4.856-2.143-4.856-4.777V8.402c0-2.634,2.179-4.777,4.856-4.777h156.037    c2.678,0,4.856,2.143,4.856,4.777v34.196C167.875,45.232,165.696,47.375,163.019,47.375z M6.981,4.375    c-2.264,0-4.106,1.807-4.106,4.027v34.196c0,2.22,1.842,4.027,4.106,4.027h156.037c2.265,0,4.106-1.807,4.106-4.027V8.402    c0-2.22-1.842-4.027-4.106-4.027H6.981z"
          />
        </g>
      </g>
      <g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M30.321,16.673c-0.089-0.007-1.981-0.148-1.981-0.148s-1.314-1.304-1.458-1.449     c-0.035-0.035-0.078-0.059-0.125-0.074l-0.937,21.652l7.403-1.608c0,0-2.67-18.05-2.687-18.173     C30.519,16.749,30.41,16.681,30.321,16.673z"
            />
          </g>
          <g>
            <path
              fill="#FFFFFF"
              d="M26.346,15.009c-0.016,0.005-0.287,0.088-0.735,0.227c-0.439-1.263-1.213-2.423-2.576-2.423     c-0.038,0-0.076,0.002-0.115,0.004c-0.388-0.512-0.867-0.735-1.282-0.735c-3.174,0-4.69,3.968-5.166,5.984     c-1.233,0.382-2.109,0.654-2.221,0.689c-0.688,0.216-0.71,0.238-0.8,0.886c-0.068,0.491-1.87,14.421-1.87,14.421l13.901,2.605     l0.938-21.675C26.392,14.997,26.366,15.003,26.346,15.009z M22.816,15.963c0,0.048,0,0.093-0.001,0.138     c-0.773,0.239-1.612,0.499-2.453,0.759c0.472-1.823,1.358-2.703,2.132-3.035C22.689,14.314,22.816,15.016,22.816,15.963z      M21.551,12.933c0.137,0,0.275,0.046,0.407,0.137c-1.017,0.479-2.108,1.684-2.568,4.092c-0.673,0.208-1.331,0.412-1.939,0.601     C17.991,15.926,19.271,12.933,21.551,12.933z M22.098,23.665c0,0-0.822-0.439-1.829-0.439c-1.477,0-1.551,0.927-1.551,1.16     c0,1.274,3.321,1.762,3.321,4.746c0,2.348-1.489,3.86-3.497,3.86c-2.409,0-3.642-1.5-3.642-1.5l0.645-2.131     c0,0,1.267,1.087,2.335,1.087c0.698,0,0.982-0.55,0.982-0.951c0-1.662-2.725-1.736-2.725-4.467c0-2.298,1.65-4.522,4.98-4.522     c1.283,0,1.917,0.368,1.917,0.368L22.098,23.665z M23.648,15.843c0-0.084,0.001-0.166,0.001-0.256     c0-0.785-0.109-1.417-0.284-1.918c0.702,0.088,1.17,0.887,1.471,1.806C24.483,15.585,24.082,15.709,23.648,15.843z"
            />
          </g>
        </g>
        <g>
          <path
            fill="#FFFFFF"
            d="M44.271,35.521c-2.49,0-3.645-1.56-3.885-3.105l1.605-0.24c0.195,1.065,0.72,2.025,2.28,2.025    c1.17,0,2.01-0.675,2.01-1.68c0-1.08-0.57-1.53-2.235-1.979c-2.16-0.525-3.3-1.305-3.3-3.15c0-1.665,1.365-2.88,3.42-2.88    c2.025,0,3.27,1.08,3.555,2.67l-1.575,0.255c-0.21-1.035-0.93-1.62-1.979-1.62c-1.2,0-1.83,0.585-1.83,1.455    c0,0.9,0.39,1.35,2.16,1.8c2.175,0.54,3.42,1.29,3.42,3.3C47.916,34.202,46.461,35.521,44.271,35.521z"
          />
          <path
            fill="#FFFFFF"
            d="M49.446,23.912h1.59v4.815c0.36-0.735,1.185-1.35,2.4-1.35c1.5,0,2.535,0.87,2.535,2.94v5.055h-1.62    v-4.965c0-1.155-0.48-1.68-1.515-1.68c-0.96,0-1.8,0.615-1.8,1.815v4.83h-1.59V23.912z"
          />
          <path
            fill="#FFFFFF"
            d="M57.291,31.157c0-2.295,1.59-3.78,3.72-3.78s3.72,1.47,3.72,3.75v0.615c0,2.31-1.605,3.78-3.735,3.78    c-2.145,0-3.705-1.5-3.705-3.75V31.157z M61.011,34.247c1.29,0,2.07-0.96,2.07-2.475v-0.63c0-1.545-0.795-2.49-2.07-2.49    c-1.29,0-2.085,0.93-2.085,2.475v0.63C58.926,33.302,59.691,34.247,61.011,34.247z"
          />
          <path
            fill="#FFFFFF"
            d="M67.7,27.527v1.245c0.435-0.75,1.35-1.395,2.46-1.395c1.845,0,3.21,1.35,3.21,3.69v0.675    c0,2.355-1.32,3.78-3.24,3.78c-1.17,0-2.025-0.6-2.43-1.35v3.885h-1.59v-10.53H67.7z M69.771,34.231c1.26,0,1.95-0.855,1.95-2.445    v-0.675c0-1.65-0.84-2.46-1.95-2.46c-1.215,0-2.1,0.81-2.1,2.505v0.585C67.67,33.437,68.495,34.231,69.771,34.231z"
          />
          <path
            fill="#FFFFFF"
            d="M74.615,25.247c0-0.54,0.435-0.96,0.975-0.96c0.555,0,0.99,0.42,0.99,0.96s-0.435,0.96-0.99,0.96    C75.035,26.207,74.615,25.787,74.615,25.247z M74.795,27.527H76.4v7.845h-1.605V27.527z"
          />
          <path
            fill="#FFFFFF"
            d="M78.575,28.802h-1.02v-1.275h1.02v-1.125c0-1.65,0.87-2.55,2.535-2.55c0.51,0,0.9,0.06,1.215,0.18v1.275    c-0.21-0.105-0.63-0.18-1.02-0.18c-0.825,0-1.11,0.48-1.11,1.215v1.185h2.04v1.275h-2.04v6.57h-1.62V28.802z"
          />
          <path
            fill="#FFFFFF"
            d="M86.72,32.717l1.86-5.19h1.59l-4.035,10.53H84.53l1.335-3.465l-2.91-7.065h1.74L86.72,32.717z"
          />
          <path
            fill="#FFFFFF"
            d="M99.89,32.612h-3.795l-0.81,2.76H93.71l3.226-10.725H99.2l3.194,10.725h-1.71L99.89,32.612z     M96.455,31.352h3.075l-1.545-5.31L96.455,31.352z"
          />
          <path
            fill="#FFFFFF"
            d="M105.185,27.527v1.245c0.436-0.75,1.351-1.395,2.46-1.395c1.846,0,3.21,1.35,3.21,3.69v0.675    c0,2.355-1.319,3.78-3.239,3.78c-1.17,0-2.025-0.6-2.431-1.35v3.885h-1.59v-10.53H105.185z M107.255,34.231    c1.26,0,1.95-0.855,1.95-2.445v-0.675c0-1.65-0.84-2.46-1.95-2.46c-1.215,0-2.1,0.81-2.1,2.505v0.585    C105.155,33.437,105.979,34.231,107.255,34.231z"
          />
          <path
            fill="#FFFFFF"
            d="M113.84,27.527v1.245c0.435-0.75,1.35-1.395,2.46-1.395c1.845,0,3.21,1.35,3.21,3.69v0.675    c0,2.355-1.32,3.78-3.24,3.78c-1.17,0-2.024-0.6-2.43-1.35v3.885h-1.59v-10.53H113.84z M115.91,34.231    c1.26,0,1.949-0.855,1.949-2.445v-0.675c0-1.65-0.84-2.46-1.949-2.46c-1.216,0-2.101,0.81-2.101,2.505v0.585    C113.81,33.437,114.635,34.231,115.91,34.231z"
          />
          <path
            fill="#FFFFFF"
            d="M127.399,35.521c-2.49,0-3.645-1.56-3.885-3.105l1.604-0.24c0.195,1.065,0.721,2.025,2.28,2.025    c1.17,0,2.01-0.675,2.01-1.68c0-1.08-0.569-1.53-2.234-1.979c-2.16-0.525-3.3-1.305-3.3-3.15c0-1.665,1.364-2.88,3.42-2.88    c2.024,0,3.27,1.08,3.555,2.67l-1.575,0.255c-0.21-1.035-0.93-1.62-1.979-1.62c-1.2,0-1.83,0.585-1.83,1.455    c0,0.9,0.39,1.35,2.159,1.8c2.176,0.54,3.42,1.29,3.42,3.3C131.044,34.202,129.59,35.521,127.399,35.521z"
          />
          <path
            fill="#FFFFFF"
            d="M134.494,25.382v2.145h1.965v1.275h-1.965v4.35c0,0.72,0.36,1.005,1.021,1.005    c0.39,0,0.765-0.06,1.035-0.18v1.275c-0.301,0.135-0.721,0.225-1.306,0.225c-1.604,0-2.354-0.795-2.354-2.175v-4.5h-1.035v-1.275    h1.035v-2.145H134.494z"
          />
          <path
            fill="#FFFFFF"
            d="M137.45,31.157c0-2.295,1.59-3.78,3.72-3.78s3.72,1.47,3.72,3.75v0.615c0,2.31-1.605,3.78-3.735,3.78    c-2.145,0-3.704-1.5-3.704-3.75V31.157z M141.17,34.247c1.29,0,2.069-0.96,2.069-2.475v-0.63c0-1.545-0.795-2.49-2.069-2.49    c-1.29,0-2.085,0.93-2.085,2.475v0.63C139.085,33.302,139.85,34.247,141.17,34.247z"
          />
          <path
            fill="#FFFFFF"
            d="M146.27,27.527h1.59v1.395c0.479-0.96,1.155-1.515,2.7-1.5v1.515c-1.771,0.015-2.7,0.495-2.7,2.13v4.305    h-1.59V27.527z"
          />
          <path
            fill="#FFFFFF"
            d="M154.804,35.521c-2.145,0-3.659-1.41-3.659-3.75v-0.615c0-2.295,1.5-3.78,3.584-3.78    c1.83,0,3.436,1.08,3.436,3.705v0.705h-5.4c0.016,1.695,0.78,2.49,2.085,2.49c1.141,0,1.695-0.705,1.86-1.29l1.395,0.33    C157.818,34.382,156.754,35.521,154.804,35.521z M152.779,30.677h3.795v-0.045c-0.061-1.44-0.75-2.04-1.846-2.04    C153.754,28.592,152.929,29.207,152.779,30.677z"
          />
        </g>
        <g>
          <path
            fill="#FFFFFF"
            d="M41.889,15.15h2.886v0.728h-1.967v1.429h1.572v0.714h-1.572v1.994h-0.919V15.15z"
          />
          <path fill="#FFFFFF" d="M46.03,15.15h0.926v4.866H46.03V15.15z" />
          <path
            fill="#FFFFFF"
            d="M48.491,15.15h1.075l1.783,3.375V15.15h0.837v4.866h-0.939l-1.926-3.641v3.641h-0.831V15.15z"
          />
          <path
            fill="#FFFFFF"
            d="M55.238,15.15c1.545,0,2.321,0.823,2.321,2.164v0.503c0,1.327-0.762,2.198-2.321,2.198h-1.552V15.15    H55.238z M54.605,15.871v3.417h0.599c0.959,0,1.402-0.518,1.402-1.47v-0.49c0-0.933-0.408-1.457-1.409-1.457H54.605z"
          />
          <path fill="#FFFFFF" d="M60.854,15.15h0.925v4.866h-0.925V15.15z" />
          <path
            fill="#FFFFFF"
            d="M64.254,15.878h-1.273V15.15h3.464v0.728H65.18v4.138h-0.925V15.878z"
          />
          <path
            fill="#FFFFFF"
            d="M69.38,17.845v-0.538c0-1.327,0.885-2.219,2.157-2.219c1.279,0,2.144,0.864,2.144,2.212v0.524    c0,1.341-0.837,2.259-2.15,2.259S69.38,19.165,69.38,17.845z M72.729,17.838v-0.531c0-0.892-0.422-1.49-1.191-1.49    s-1.198,0.599-1.198,1.49v0.531c0,0.932,0.436,1.504,1.198,1.504S72.729,18.763,72.729,17.838z"
          />
          <path
            fill="#FFFFFF"
            d="M75.004,15.15h1.075l1.783,3.375V15.15h0.837v4.866H77.76l-1.926-3.641v3.641h-0.831V15.15z"
          />
          <path
            fill="#FFFFFF"
            d="M83.083,15.878H81.81V15.15h3.464v0.728h-1.266v4.138h-0.925V15.878z"
          />
          <path
            fill="#FFFFFF"
            d="M86.455,15.15h0.919v2.042h1.879V15.15h0.918v4.866h-0.918V17.94h-1.879v2.076h-0.919V15.15z"
          />
          <path
            fill="#FFFFFF"
            d="M91.678,15.15h2.906v0.728h-1.987v1.293h1.605v0.688h-1.605v1.429h2.096v0.728h-3.015V15.15z"
          />
        </g>
      </g>
    </svg>
  );
}

const MemoShopify = React.memo(Shopify);
export default MemoShopify;
